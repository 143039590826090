import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DateServizio } from '../_interfaces/dateServizio';

@Injectable({
  providedIn: 'root'
})
export class DateServizioService {

  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getByTabellaCampo(tabella:string, id: string): Observable<{ data: DateServizio[] }> {
    return this.http.get(`${this.baseUrl}/date_servizio/by_tc/${tabella}/${id}`).pipe(
      map((res: { data: DateServizio[] }) => res)
    );
  }

  getByTabellaCampoDescr(id:string, campo: string): Observable<{ data: DateServizio[] }> {
    return this.http.get(`${this.baseUrl}/date_servizio/by_tc/${id}/${campo}`).pipe(
      map((res: { data: DateServizio[] }) => res)
    );
  }


  add(mb: DateServizio): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/date_servizio/add`, mb).pipe(
      map((res: { res: string, message: string }) => res));
  }



}