import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MiltibeneficiariService } from "src/app/_services/miltibeneficiari.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Multibeneficiario } from 'src/app/_interfaces/multibeneficiario';
import { NazioniService } from 'src/app/_services/nazioni.service';
import { AutoComplete } from 'primeng/autocomplete';
import { Regioni, Citta } from 'src/app/_interfaces/nazioni';
import * as _ from 'lodash'; // Libreria per il controllo di dati vuoti
import { TitolaritaImmobile } from 'src/app/_interfaces/list-type';
import { ListTypeService } from 'src/app/_services/list-type.service';

@Component({
  selector: 'app-multibeneficiario',
  templateUrl: './multibeneficiario.component.html',
  styleUrls: ['./multibeneficiario.component.scss']
})
export class MultibeneficiarioComponent implements OnInit {
  multiBn: Multibeneficiario[];
  newMemberForm: FormGroup;
  displayAddDialog: boolean = false;

  nazioniSuggestion: any[] = [];
  regioniSuggestion: any[] = [];
  cittaSuggestion: any[] = [];

  nazioniNascitaSuggestion: any[] = [];
  regioniNascitaSuggestion: any[] = [];
  cittaNascitaSuggestion: any[] = [];


  @Input() id!: string;
  @Input() tipo!: Number;
  @Input() mMultiB!: boolean;
  @Input() ruolo!: string;
  @Input() serramentistaID!: Number;
  @Input() MB_AC!: string;

  @ViewChild('mb_regione_nascita', { static: false })
  mb_regione_nascita: AutoComplete;
  @ViewChild('mb_citta_nascita', { static: false })
  mb_citta_nascita: AutoComplete;
  @ViewChild('mb_regione_r', { static: false })
  mb_regione_r: AutoComplete;
  @ViewChild('mb_citta_r', { static: false })
  mb_citta_r: AutoComplete;

  tipoOptions = [
    { label: 'Fisica', value: 'fisica' },
    { label: 'Giuridica', value: 'giuridica' }
  ];

  tipologiaOptions = [
    { label: 'Multibeneficiario', value: 'M' },
    { label: 'Autocertificazione', value: 'A' },
    // { label: 'Multibeneficiario/Autocertificazione', value: 'E' }
  ];

  tipologia: any;

  constructor(
    private fb: FormBuilder,
    private mbService: MiltibeneficiariService,
    private listTypeSrv: ListTypeService,
    private nazioniSrv: NazioniService,
  ) {  }

  nazioni: any[] = []; // Lista di nazioni
  regioni: any[] = []; // Lista di regioni
  citta: any[] = []; // Lista di città
  cittaNascita: any[] = []; // Lista di città
  provincia: string = ""; // Provincia selezionata
  titImmobile: TitolaritaImmobile[];

  ngOnInit(): void {
    this.refreshTitolariImmobili();
    this.refreshMultibeneficiari(this.id);
    this.refreshNazioni();
    this.refreshRegioni();
    this.GeneraMembro()
  }

  GeneraMembro(): void {
    this.newMemberForm = this.fb.group({
      mb_id: [0],
      mb_prev_id: [0],
      mb_lav_id: [0],
      mb_ha_piva: [0],
      mb_serramentista: [0],
      mb_tipo: [''],
      mb_titolareita: [0],
      mb_tipologia: [''],
      mb_nome: ['', Validators.required],
      mb_cognome: ['', Validators.required],
      mb_data_nascita: ['', Validators.required],
      mb_nazione_nascita: [''],
      mb_regione_nascita: [''],
      mb_provincia_nascita: [''],
      mb_citta_nascita: [''],
      mb_telefono: [''],
      mb_email: [''],
      mb_piva: [''],
      mb_cf: ['', [Validators.required, Validators.pattern('[A-Za-z0-9]{16}')]], // Ad esempio, un codice fiscale di 16 caratteri
      mb_nazione_r: [''],
      mb_citta_r: [''],
      mb_regione_r: [''],
      mb_provincia_r: [''],
      mb_indirizzo_r: [''],
      mb_civico_r: [''],
      mb_cap_r: [''],
      mb_canc: [0],
      mb_note: [''],
    });
  }

  refreshNazioni(): void {
    this.nazioniSrv
      .getNazioni()
      .subscribe((res) => (this.nazioni = [...res.data]));
  }

  refreshRegioni(): void {
    this.nazioniSrv
      .getRegioni()
      .subscribe((res) => (this.regioni = [...res.data]));
  }

  refreshCittaNascita(ev, key: string, section: string = ''): void {
    const regione: string = ev.value;
    if (_.isEmpty(regione)) {
      return;
    }
    this.nazioniSrv.getCittaByRegione(regione).subscribe((res) => {
      this.cittaNascita = [...res.data];
    });
    this.newMemberForm.controls[key].setValue(regione);
    this.mb_regione_nascita.inputEL.nativeElement.value = regione;
  }

  refreshCittaResidenza(ev, key: string, section: string = ''): void {
    const regione: string = ev.value;
    if (_.isEmpty(regione)) {
      return;
    }
    this.nazioniSrv.getCittaByRegione(regione).subscribe((res) => {
      this.citta = [...res.data];
    });
    this.newMemberForm.controls[key].setValue(regione);
    this.mb_regione_r.inputEL.nativeElement.value = regione;
  }

  refreshTitolariImmobili(): void {
    this.listTypeSrv.getTitolaritaImmobile().subscribe((res) => {
      this.titImmobile = [...res.data].filter((item) => item.ti_id != 6 && item.ti_id != 15);
    });
  }

  search(ev, type): void {
    const query: string = ev.query;
    switch (true) {
      case type === 'regioni':
        this.regioniSuggestion = [
          ...this.regioni.filter(
            (r: Regioni) =>
              r.value
                .toLowerCase()
                .indexOf(query.toLowerCase()) !== -1
          ),
        ];
        break;
      case type === 'citta':
        this.cittaSuggestion = [
          ...this.citta.filter(
            (c: Citta) =>
              c.value
                .toLowerCase()
                .indexOf(query.toLowerCase()) !== -1
          ),
        ];
        break;
    }
  }

  searchNascita(ev, type): void {
    const query: string = ev.query;
    switch (true) {
      case type === 'regioni':
        this.regioniNascitaSuggestion = [
          ...this.regioni.filter(
            (r: Regioni) =>
              r.value
                .toLowerCase()
                .indexOf(query.toLowerCase()) !== -1
          ),
        ];
        break;
      case type === 'citta':
        this.cittaNascitaSuggestion = [
          ...this.cittaNascita.filter(
            (c: Citta) =>
              c.value
                .toLowerCase()
                .indexOf(query.toLowerCase()) !== -1
          ),
        ];
        break;
    }
  }

  selectProvinciaNascita(ev: Citta, key: string): void {
    this.newMemberForm.controls[key].setValue(ev.value);
    this.mb_citta_nascita.inputEL.nativeElement.value = ev.value;
    this.newMemberForm.controls['mb_provincia_nascita'].setValue(ev.provincia);
  }

  selectProvinciaResidenza(ev: Citta, key: string): void {
    this.newMemberForm.controls[key].setValue(ev.value);
    this.mb_citta_r.inputEL.nativeElement.value = ev.value;
    this.newMemberForm.controls['mb_provincia_r'].setValue(ev.provincia);
  }

  resetDatiNascita(): void {
    this.newMemberForm.controls.mb_regione_nascita.reset();
    this.newMemberForm.controls.mb_citta_nascita.reset();
    this.newMemberForm.controls.mb_provincia_nascita.reset();
  }

  resetDatiResidenza(): void {
    this.newMemberForm.controls.mb_regione_r.reset();
    this.newMemberForm.controls.mb_citta_nr.reset();
    this.newMemberForm.controls.mb_provincia_r.reset();
  }

  refreshMultibeneficiari(id: string): void {
    this.mbService.getMbByPrev(id).subscribe((res) => {
      this.multiBn = [...res.data].filter((x) => {
        if (this.MB_AC == 'M') {
          return x.mb_tipologia == 'M' || x.mb_tipologia == 'E'; // Restituisci true o false
        } else {
          return x.mb_tipologia == 'A'; // Restituisci true o false
        }
      });
    });
  }


  showAddDialog() {
    this.tipologia = this.tipologiaOptions.filter((x) => {
      if (this.MB_AC == 'M') {
        return x.value == 'M' || x.value == 'E'; // Restituisci true o false
      } else {
        return x.value == 'A'; // Restituisci true o false
      }
    });
    this.newMemberForm.reset();
    this.displayAddDialog = true;
  }

  hideAddDialog() {
    this.displayAddDialog = false;
  }

  // Aggiungi un nuovo membro alla lista
  addMember() {
    if (this.newMemberForm.valid) {
      this.newMemberForm.controls['mb_prev_id'].setValue(this.id);
      this.newMemberForm.controls['mb_lav_id'].setValue(0);
      this.newMemberForm.controls['mb_canc'].setValue(0);
      this.newMemberForm.controls['mb_serramentista'].setValue(this.serramentistaID);
      if (this.newMemberForm.controls['mb_piva'].value == null ||
        this.newMemberForm.controls['mb_piva'].value == undefined ||
        this.newMemberForm.controls['mb_piva'].value == '') {
        this.newMemberForm.controls['mb_ha_piva'].setValue(0);
      }
      else {
        this.newMemberForm.controls['mb_ha_piva'].setValue(1);
      }

      this.mbService.addMultibeneficiario(this.newMemberForm.value).subscribe((res) => {
        this.refreshMultibeneficiari(this.id)
      });
      this.newMemberForm.reset();
      this.hideAddDialog(); // Chiudi il dialog
    }
  }

  editMemberDialog(member: any): void {
    this.newMemberForm.reset();
    this.newMemberForm.patchValue(member);

    this.mb_regione_nascita.inputEL.nativeElement.value = member.mb_regione_nascita;
    this.mb_regione_r.inputEL.nativeElement.value = member.mb_regione_r;

    this.tipologia = this.tipologiaOptions.filter((x) => {
      if (this.MB_AC == 'M') {
        return x.value == 'M' || x.value == 'E'; // Restituisci true o false
      } else {
        return x.value == 'A'; // Restituisci true o false
      }
    });
    if (this.MB_AC == 'M') {
      this.newMemberForm.controls['mb_tipologia'].setValue('M')
    } else if (this.MB_AC == 'E') {
      this.newMemberForm.controls['mb_tipologia'].setValue('E')

    } else if (this.MB_AC == 'A') {
      this.newMemberForm.controls['mb_tipologia'].setValue('A')

    }


    this.nazioniSrv.getCittaByRegione(member.mb_regione_nascita).subscribe((res) => {
      this.cittaNascita = [...res.data];
      this.mb_citta_nascita.inputEL.nativeElement.value = member.mb_citta_nascita;
    });
    this.nazioniSrv.getCittaByRegione(member.mb_regione_r).subscribe((res) => {
      this.citta = [...res.data];
      this.mb_citta_r.inputEL.nativeElement.value = member.mb_citta_r;
    });
    this.displayAddDialog = true;
  }
}
