import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { OverlayPanel } from "primeng/overlaypanel";
import {
    ProdottiInstallati,
    TipoIntervento,
    ZonaClimatica,
} from "src/app/_interfaces/list-type";
import { ListTypeService } from "src/app/_services/list-type.service";

@Component({
    selector: "app-tipologia-select",
    templateUrl: "./tipologia-select.component.html",
    styleUrls: ["./tipologia-select.component.scss"],
})
export class TipologiaSelectComponent implements OnInit {
    @Input() tipologia: number;


    @Input() tipoBonus: string;    @Input() editabile: boolean;
    @Input() partitaIva: string;

    frmPreventivo: FormGroup;
    form!: FormGroup;

    ArrTipologiaBC = [];
    filtroTipoProdotto: ProdottiInstallati[];
    ArrEsposizioneChiusureOscuranti = [];
    loading: boolean = true;

    @Input() descrizione: string;

    @ViewChild("opBC", { static: false }) opBC: OverlayPanel;
    @ViewChild("opECO", { static: false }) opECO: OverlayPanel;
    @ViewChild("opZC", { static: false }) opZC: OverlayPanel;
    @ViewChild("opESS", { static: false }) opESS: OverlayPanel;

    tipoInterventoOptions: TipoIntervento[];
    tipoInterventoOptionsBC: TipoIntervento[];
    tipoInterventoOptionsBA: TipoIntervento[];

    tipoInterventoOptionsBC_AI: TipoIntervento[];
    tipoInterventoOptionsBA_AI: TipoIntervento[];

    filtroZonelimatiche: ZonaClimatica[];
    zonelimatiche: ZonaClimatica[];

    ArrEsposizioneSchermatureSolari = [];
    selZonaClimatica: string = "";

    chiusuraOscurante: { label: string; value: any }[] = [
        { label: "N/D", value: 99 },
        { label: "SI", value: 1 },
        { label: "NO", value: 0 },
        { label: "<=0.35", value: 2 },
    ];

    filtroChiusuraOscurante: { label: string; value: any }[] =
        this.chiusuraOscurante;

    TipologiaImmobiliare: { label: string; value: any }[] = [
        { label: "Singola unità abitativa", value: "Singola unità abitativa" },
        {
            label: "Parti comuni condominiali",
            value: "Parti comuni condominiali",
        },
    ];

    tipologiaBonusSelect: { label: string; value: any }[] = [
        { label: "Detrazione diretta Eco Bonus", value: "EB" },
        { label: "Detrazione diretta Bonus Casa serramenti e infissi", value: "BC_SI" },
        { label: "Detrazione diretta Bonus Casa altri interventi", value: "BC_AI" },
        { label: "Detrazione diretta Bonus Barriere Architettoniche serramenti e infissi", value: "BA" },
        { label: "Detrazione diretta Bonus Barriere Architettoniche altri interventi", value: "BA_AI" },
    ];

    serramentoTipoProdotto: ProdottiInstallati[];

    constructor(
        private listTypeSrv: ListTypeService,
        private rootFormGroup: FormGroupDirective
    ) { }

    async ngOnInit() {
        console.log(" this.partitaIva ", this.partitaIva)
        if (this.partitaIva === 'SI') {
            this.tipologiaBonusSelect = this.tipologiaBonusSelect.filter(item => item.value !== "BC_SI" && item.value !== "BC_AI");
        }
        await this.refreshTipoloInterventi();
        this.refreshProdottiInstallati();
        this.refreshZoneClimatiche();
        this.form = this.rootFormGroup.control.get("tipologiaSelectForm") as FormGroup;
        if (this.form.controls.prev_tipologia_intervento.value != "" || this.form.controls.prev_tipologia_intervento.value == 0) {
            if (this.form.controls.prev_tipo_detrazione.value == "BA") {
                this.form.controls.prev_tipologia.setValue("BA");
            }
            else if (this.form.controls.prev_tipo_detrazione.value == "BC_SI") {
                this.form.controls.prev_tipologia.setValue("BC_SI");
            }
            else if (this.form.controls.prev_tipo_detrazione.value == "BC_AI") {
                this.form.controls.prev_tipologia.setValue("BC_AI");
            }
            else if (this.form.controls.prev_tipo_detrazione.value == "BA_AI") {
                this.form.controls.prev_tipologia.setValue("BA_AI");
            }
            else if (this.form.controls.prev_tipologia.value != "BC" || this.form.controls.prev_tipologia.value == "EB") {
                this.form.controls.prev_tipologia.setValue("EB");
            }
            this.selectTipologia();
        }
        this.loading = false;
    }

    Sel_ZC_ESS(tipoScelto: string): void {
        if (tipoScelto === "ESS") {
            this.form.controls.prev_zona_climatica.setValue(
                this.ArrEsposizioneSchermatureSolari.join(",")
            );
            this.opESS.hide();
        } else if (tipoScelto === "ZC") {
            this.form.controls.prev_zona_climatica.setValue(
                this.selZonaClimatica
            );
            this.opZC.hide();
        }
    }

    Trasmittanza(ev): void {
        if (ev.value === "A" || ev.value === "B") {
            this.form.controls.prev_transmittanza.setValue("<= a 2,60 W/m2k");
        } else if (ev.value === "C") {
            this.form.controls.prev_transmittanza.setValue("<= a 1,75 W/m2k");
        } else if (ev.value === "D") {
            this.form.controls.prev_transmittanza.setValue("<= a 1,67 W/m2k");
        } else if (ev.value === "E") {
            this.form.controls.prev_transmittanza.setValue("<= a 1,30 W/m2k");
        } else if (ev.value === "F") {
            this.form.controls.prev_transmittanza.setValue("<= a 1,00 W/m2k");
        }
    }

    refreshZoneClimatiche(): void {
        this.listTypeSrv.getZonaClimatica().subscribe((res) => {
            this.zonelimatiche = [...res.data];
            this.filtroZonelimatiche = [...res.data];
        });
    }

    refreshProdottiInstallati(): void {
        this.listTypeSrv.getProdottiInstallati().subscribe((res) => {
            this.serramentoTipoProdotto = [...res.data];
            this.filtroTipoProdotto = [...res.data];
        });
    }

    Sel_EsposizioneChiusureOscuranti(): void {
        this.form.controls.prev_prodotto_installato.setValue(
            this.ArrEsposizioneChiusureOscuranti.join(",")
        );
        this.opECO.hide();
    }

    selectTipologia() {
        var idTipologiaIntervento = this.form.controls.prev_tipologia_intervento.value;

        this.form.controls.prev_zona_climatica.enable();
        this.form.controls.prev_mq_installati_co.enable();
        this.form.controls.prev_prodotto_installato.enable();
        this.form.controls.prev_mq_installati.enable();
        this.form.controls.prev_chiusura.enable();
        this.form.controls.prev_tipo_bc.enable();
        this.form.controls.prev_tipo_unita.enable();
        this.form.controls.prev_mq_installati_co.enable();

        if (this.form.controls.prev_tipologia.value === "BC_AI" || this.form.controls.prev_tipologia.value === "BA_AI") {

            this.form.controls.prev_chiusura.disable();
            this.form.controls.prev_zona_climatica.disable();
            this.form.controls.prev_transmittanza.disable();
            this.form.controls.prev_prodotto_installato.disable();
            this.form.controls.prev_mq_installati.disable();
            this.form.controls.prev_mq_installati_co.disable();
        } else {
            if (idTipologiaIntervento == 5) {
                this.form.controls.prev_tipo_unita.disable();
                this.form.controls.prev_tipo_bc.disable();
                this.form.controls.prev_zona_climatica.disable();
                this.form.controls.prev_mq_installati_co.disable();
            } else if (idTipologiaIntervento == 6) {
                this.form.controls.prev_tipo_unita.disable();
                this.form.controls.prev_mq_installati_co.disable();
                this.form.controls.prev_tipo_bc.disable();
            } else if (idTipologiaIntervento == 2) {
                this.form.controls.prev_tipo_unita.disable();
                this.form.controls.prev_mq_installati_co.disable();
                this.form.controls.prev_prodotto_installato.disable();
                this.form.controls.prev_tipo_bc.disable();
            } else if (idTipologiaIntervento === 1) {
                this.form.controls.prev_tipo_unita.disable();
                this.form.controls.prev_tipo_bc.disable();
            }
        }
        this.form.updateValueAndValidity();
    }

    cambiaTipologiaIntervento(e) {
        this.refreshTipoloInterventi();
        if (this.form.controls.prev_tipologia.value === "BC_AI") {
            this.tipoInterventoOptionsBC = this.tipoInterventoOptionsBC_AI
        }

        if (this.form.controls.prev_tipologia.value === "BA_AI") {
            this.tipoInterventoOptionsBC = this.tipoInterventoOptionsBA_AI
        }
    }

    Sel_BC(): void {
        this.form.controls.prev_tipo_bc.setValue(
            this.ArrTipologiaBC.join(",")
        );
        this.opBC.hide();
    }

    refreshTipoloInterventi(): Promise<void> {
        return new Promise((resolve) =>
            this.listTypeSrv.getTipoInterventi().subscribe((res) => {
                const userInterventi = sessionStorage.getItem("interventi");
                if (userInterventi === "0") {
                    this.tipoInterventoOptions = [
                        ...res.data.filter((f) => f.tin_codice == "SI"),
                    ];
                } else if (userInterventi === "2") {
                    this.tipoInterventoOptions = [
                        ...res.data.filter((f) => f.tin_codice == "SSCO"),
                    ];
                } else {
                    this.tipoInterventoOptions = [
                        ...res.data.filter((f) => f.tin_codice == "SI" || f.tin_codice == "SSCO"),
                    ];
                }
                this.tipoInterventoOptionsBC_AI = [
                    ...res.data.filter((f) => f.tin_codice == "BC_AI"),
                ];
                this.tipoInterventoOptionsBA_AI = [
                    ...res.data.filter((f) => f.tin_codice == "BA_AI"),
                ];
                // da sistemare per pratiche BA
                this.tipoInterventoOptionsBA = [
                    ...res.data.filter((f) => f.tin_codice == "SI"),
                ];
                resolve();
            })
        );

    }

    OpenEspChiusureOsc(eve): void {
        this.ArrEsposizioneChiusureOscuranti = [];
        this.opECO.show(eve);
    }

    changeValueTipologiaIntervento(e): void {
        var idTipoIntervento =
            this.form.controls.prev_tipologia_intervento.value;

        if (idTipoIntervento == 5 || idTipoIntervento == 6) {
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter(
                (f) => f.value == 2
            );
            this.form.controls.prev_chiusura.setValue(2);
            this.form.controls.prev_tipologia_agevolazione.setValue(50);
            this.form.controls.prev_transmittanza.setValue(
                "0,08 Km2/w <= Rsup <= 0,455 Km2/w"
            );
            this.form.controls.prev_mq_installati.setValue(0);
            this.form.controls.prev_mq_installati_co.setValue(null);
            this.form.controls.prev_prodotto_installato.setValue(null);
        } else if (idTipoIntervento == 2) {
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter(
                (f) => f.value == 2
            );
            this.form.controls.prev_chiusura.setValue(2);
            this.form.controls.prev_tipologia_agevolazione.setValue(50);
            this.form.controls.prev_transmittanza.setValue(null);
            this.form.controls.prev_mq_installati.setValue(0);
            this.form.controls.prev_mq_installati_co.setValue(null);
            this.form.controls.prev_prodotto_installato.setValue(null);
        } else if (idTipoIntervento == 1) {
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter(
                (f) => f.value == 99
            );
            this.form.controls.prev_chiusura.setValue(99);
            this.form.controls.prev_transmittanza.setValue(0);
            this.form.controls.prev_tipologia_agevolazione.setValue(50);
            this.form.controls.prev_mq_installati.setValue(0);
            this.form.controls.prev_mq_installati_co.setValue(0);

            this.form.controls.prev_prodotto_installato.setValue(
                "Serramento o Serramento + chiusura oscurante"
            );
        }

        if (idTipoIntervento == 1) {
            this.filtroZonelimatiche = this.zonelimatiche.filter(
                (f) =>
                    f.zc_codice === "A" ||
                    f.zc_codice === "B" ||
                    f.zc_codice === "C" ||
                    f.zc_codice === "D" ||
                    f.zc_codice === "E" ||
                    f.zc_codice === "F"
            );
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter(
                (f) => f.pi_id <= 2
            );
        } else {
            this.filtroZonelimatiche = this.zonelimatiche.filter(
                (f) =>
                    f.zc_codice != "A" &&
                    f.zc_codice != "B" &&
                    f.zc_codice != "C" &&
                    f.zc_codice != "D" &&
                    f.zc_codice != "E" &&
                    f.zc_codice != "F"
            );
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter(
                (f) => f.pi_id > 2
            );
        }
    }

    OpenZonaClimatica_ChiusiraOscurate(eve): void {
        if (this.form.controls.prev_tipologia_intervento.value === 1) {
            this.selZonaClimatica = "";
            this.opZC.show(eve);
        } else {
            this.ArrEsposizioneSchermatureSolari = [];
            this.opESS.show(eve);
        }
    }

    Open_BC(eve): void {
        this.ArrTipologiaBC = [];
        this.opBC.show(eve);
    }

    getTipologiaInterventoDescription(): string {
        var tipologiaSelezionata = this.rootFormGroup.control.controls[
            "tipologiaSelectForm"
        ].get("prev_tipologia_intervento").value;

        if (tipologiaSelezionata == "") {
            return "";
        }
        this.form.controls.prev_mostra_si_no.setValue("non mostrare");

        return this.tipoInterventoOptions.find(
            (x) => x.tin_id === tipologiaSelezionata
        ).tin_nome;
    }

    onInputChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.value && parseFloat(input.value) < 0) {
          input.value = input.value.replace(/^-/, ''); // Rimuovi il segno negativo
        }
      }


}
