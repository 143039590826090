import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { OffertaComponent } from "./_components/Offerta/offerta.component";
import { ArchivioCategoriaComponent } from "./_components/archivio-categoria/archivio-categoria.component";
import { ArchivioComponent } from "./_components/archivio/archivio.component";
import { AttestazioneCessioneComponent } from "./_components/attestazione-cessione/attestazione-cessione.component";
import { CessionarioFinaleListComponent } from "./_components/cessionario-finale-list/cessionario-finale-list.component";
import { CessionarioFinaleComponent } from "./_components/cessionario-finale/cessionario-finale.component";
import { CessioneCreditoComponent } from "./_components/cessione-credito/cessione-credito.component";
import { LavoriExportComponent } from "./_components/lavori-export/lavori-export.component";
import { LavoriPraticheComponent } from "./_components/lavori-pratiche/lavori-pratiche.component";
import { LavoriStepComponent } from "./_components/lavori-step/lavori-step.component";
import { LiquidazioneComponent } from "./_components/liquidazione/liquidazione.component";
import { OffertaBCComponent } from "./_components/offerta-bc/offerta-bc.component";
import { OffertaNewComponent } from "./_components/offerta-new/offerta-new.component";
import { OfferteComponent } from "./_components/offerte/offerte.component";
import { PreventiviListPageComponent } from "./_components/preventivi/pages/preventivi-list-page/preventivi-list-page.component";
import { PreventivoPageComponent } from "./_components/preventivi/pages/preventivo-page/preventivo-page.component";
import { PreventiviExportComponent } from "./_components/preventivi/preventivi-export/preventivi-export.component";
import { SchedaInterventoComponent } from "./_components/scheda-intervento/scheda-intervento.component";
import { SchedeInterventiComponent } from "./_components/schede-interventi/schede-interventi.component";
import { SerramentistaComponent } from "./_components/serramentista/serramentista.component";
import { UtentiExportComponent } from "./_components/utenti-export/utenti-export.component";
import { UtentiProfilazioneComponent } from "./_components/utenti-profilazione/utenti-profilazione.component";
import { UtentiComponent } from "./_components/utenti/utenti.component";
import { AuthGuard } from "./_guard/auth.guard";
import { RoleGuard } from "./_guard/role.guard";
import { SerrGuard } from "./_guard/serr.guard";
import { AppMainComponent } from "./app.main.component";
import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
import { DocumentationComponent } from "./demo/view/documentation.component";
import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
import { FileDemoComponent } from "./demo/view/filedemo.component";
import { FloatLabelDemoComponent } from "./demo/view/floatlabeldemo.component";
import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
import { InputDemoComponent } from "./demo/view/inputdemo.component";
import { InvalidStateDemoComponent } from "./demo/view/invalidstatedemo.component";
import { ListDemoComponent } from "./demo/view/listdemo.component";
import { MediaDemoComponent } from "./demo/view/mediademo.component";
import { MenusDemoComponent } from "./demo/view/menusdemo.component";
import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
import { MiscDemoComponent } from "./demo/view/miscdemo.component";
import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
import { TableDemoComponent } from "./demo/view/tabledemo.component";
import { TreeDemoComponent } from "./demo/view/treedemo.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppCalendarComponent } from "./pages/app.calendar.component";
import { AppCrudComponent } from "./pages/app.crud.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppHelpComponent } from "./pages/app.help.component";
import { AppInvoiceComponent } from "./pages/app.invoice.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { RecoverComponent } from "./pages/app.recover.component";
import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
import { DisplayComponent } from "./utilities/display.component";
import { ElevationComponent } from "./utilities/elevation.component";
import { FlexboxComponent } from "./utilities/flexbox.component";
import { GridComponent } from "./utilities/grid.component";
import { IconsComponent } from "./utilities/icons.component";
import { SpacingComponent } from "./utilities/spacing.component";
import { TextComponent } from "./utilities/text.component";
import { TypographyComponent } from "./utilities/typography.component";
import { WidgetsComponent } from "./utilities/widgets.component";
import { CreditiPageComponent } from "./_components/crediti/crediti-page/crediti-page.component";
import { ParametrREComponent } from "./_components/parametr-re/parametr-re.component";
import { LavoriStepNewComponent } from "./_components/lavori-step-new/lavori-step-new.component";

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: "",
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    canActivateChild: [AuthGuard],
                    children: [
                        // {path: '', component: DashboardDemoComponent},
                        {
                            path: "",
                            component: OfferteComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "SchedeIntervento",
                            component: SchedeInterventiComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "SchedeIntervento/nuovo",
                            component: SchedaInterventoComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "SchedeIntervento/:id",
                            component: SchedaInterventoComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "utenteprofilazione/:id",
                            component: UtentiProfilazioneComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "utenti",
                            component: UtentiComponent,
                            canActivate: [AuthGuard, RoleGuard],
                            data: { minRole: "Admin" },
                        },
                        {
                            path: "Offerta/:id",
                            component: OffertaComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "OffertaNew/:id",
                            component: OffertaNewComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "OffertaBC/:id",
                            component: OffertaBCComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "offerte",
                            component: OfferteComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "lavori",
                            component: LavoriPraticheComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "lavoro/:id",
                            component: LavoriStepComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "lavoronew/:id",
                            component: LavoriStepNewComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "serramentista/:id",
                            component: SerramentistaComponent,
                        },
                        {
                            path: "ArchivioCategorie",
                            component: ArchivioCategoriaComponent,
                        },
                        {
                            path: "Archivio",
                            component: ArchivioComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "CessioneCredito",
                            component: CessioneCreditoComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "AttestazioneCessione",
                            component: AttestazioneCessioneComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "Liquidazione",
                            component: LiquidazioneComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "LavoriExport/:tipo",
                            component: LavoriExportComponent,
                        },
                        {
                            path: "UtentiExport",
                            component: UtentiExportComponent,
                        },
                        {
                            path: "CessionarioFinaleList",
                            component: CessionarioFinaleListComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "CessionarioFinale/:id",
                            component: CessionarioFinaleComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "Crediti",
                            component: CreditiPageComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "ParametriRE",
                            component: ParametrREComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "uikit/formlayout",
                            component: FormLayoutDemoComponent,
                            canActivate: [AuthGuard, RoleGuard],
                            data: { minRole: "SuperAdmin" },
                        },
                        {
                            path: "uikit/floatlabel",
                            component: FloatLabelDemoComponent,
                            canActivate: [AuthGuard, RoleGuard],
                            data: { minRole: "SuperAdmin" },
                        },
                        {
                            path: "uikit/invalidstate",
                            component: InvalidStateDemoComponent,
                            canActivate: [AuthGuard, RoleGuard],
                            data: { minRole: "SuperAdmin" },
                        },
                        {
                            path: "uikit/input",
                            component: InputDemoComponent,
                            canActivate: [AuthGuard, RoleGuard],
                            data: { minRole: "SuperAdmin" },
                        },
                        {
                            path: "uikit/button",
                            component: ButtonDemoComponent,
                            canActivate: [AuthGuard, RoleGuard],
                            data: { minRole: "SuperAdmin" },
                        },
                        { path: "uikit/table", component: TableDemoComponent },
                        { path: "uikit/list", component: ListDemoComponent },
                        { path: "uikit/tree", component: TreeDemoComponent },
                        { path: "uikit/panel", component: PanelsDemoComponent },
                        {
                            path: "uikit/overlay",
                            component: OverlaysDemoComponent,
                        },
                        { path: "uikit/menu", component: MenusDemoComponent },
                        { path: "uikit/media", component: MediaDemoComponent },
                        {
                            path: "uikit/message",
                            component: MessagesDemoComponent,
                        },
                        { path: "uikit/misc", component: MiscDemoComponent },
                        {
                            path: "uikit/charts",
                            component: ChartsDemoComponent,
                        },
                        { path: "uikit/file", component: FileDemoComponent },
                        {
                            path: "utilities/display",
                            component: DisplayComponent,
                        },
                        {
                            path: "utilities/elevation",
                            component: ElevationComponent,
                        },
                        {
                            path: "utilities/flexbox",
                            component: FlexboxComponent,
                        },
                        { path: "utilities/grid", component: GridComponent },
                        { path: "utilities/icons", component: IconsComponent },
                        {
                            path: "utilities/widgets",
                            component: WidgetsComponent,
                        },
                        {
                            path: "utilities/spacing",
                            component: SpacingComponent,
                        },
                        {
                            path: "utilities/typography",
                            component: TypographyComponent,
                        },
                        { path: "utilities/text", component: TextComponent },
                        { path: "pages/crud", component: AppCrudComponent },
                        {
                            path: "pages/calendar",
                            component: AppCalendarComponent,
                        },
                        {
                            path: "pages/timeline",
                            component: AppTimelineDemoComponent,
                        },
                        {
                            path: "pages/invoice",
                            component: AppInvoiceComponent,
                        },
                        { path: "pages/help", component: AppHelpComponent },
                        { path: "pages/empty", component: EmptyDemoComponent },
                        {
                            path: "documentation",
                            component: DocumentationComponent,
                        },
                        {
                            path: "preventivi",
                            component: PreventiviListPageComponent,
                            canActivate: [SerrGuard],
                        },
                        {
                            path: "preventivi/nuovo",
                            component: PreventivoPageComponent,
                        },
                        {
                            path: "preventivi/:id",
                            component: PreventivoPageComponent,
                        },
                        {
                            path: "preventiviExport",
                            component: PreventiviExportComponent,
                        },
                    ],
                },
                { path: "error", component: AppErrorComponent },
                { path: "access", component: AppAccessdeniedComponent },
                { path: "notfound", component: AppNotfoundComponent },
                { path: "login", component: AppLoginComponent },
                // { path: 'login', component: AppNotfoundComponent },
                { path: "recover/:hash", component: RecoverComponent },
                { path: "**", redirectTo: "/notfound" },
            ],
            { scrollPositionRestoration: "enabled" }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
